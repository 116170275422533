html, #parent {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {

}

h1 {
  font-weight: 100;
}

.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo img {
  width: 66%;
}

.wall-of-posts .logo img {
  width: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#message-board {
  color: white;
  height: 600px;
  overflow-y: scroll;
}

.speechbubble-column {
  display: flex;
  flex-wrap: wrap;
}

.speechbubble {
  color: #333;
  font-size: 1rem;
  line-height: 1.75;
  cursor: default;
  display: inline-block;
  width: 100%;
  border: 1px solid #999;
  min-height: 100px;
  box-sizing: border-box;
  max-width: 35%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  border-radius: 12px;
}
.speechbubble .username {
  display: block;
  font-style: italic;
  background: #333;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 12px 12px 0 0;
}
.speechbubble .username:before {
  content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%23ffffff%7D%3C/style%3E%3Cpath d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'/%3E%3C/svg%3E");
  padding-right: 10px;
}
.speechbubble p {
  padding: 15px;
}

#breathe-video {
  text-align: center;
}

#breathe-video a {
  border: 3px solid #333;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
}

.social-bar-container-wrapper {
  display: inline-block;
  padding: 8px;
  border: 3px solid #333;
  padding-bottom: 20px;
  border-radius: 8px;
}

#menu-social-menu li,#menu-social-menu li:hover {
  transition: none;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}

.social-bar-container .menu {
  height: 22px;
}

.social-bar-container ul {
  list-style: none;
    padding: 0;
    margin: 0;
}

.social-bar-container li {
  width: 36px;
  height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 21px;
  text-align: center;
  color: #000;
  opacity: .8;
}

.social-bar-container .menu li a {
  border-left: none;
  padding: 0;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  display: block;
  position: relative;
  color: #000;
}

.social-bar-container .menu li:hover {
  opacity: 1;
  color: #b00900
}

#breathe-video a:hover {
  color: #f60;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
